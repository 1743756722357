export const ALERT_MESSAGES = {
  EMAIL_FORMAT_INCORRECT: 'Email format is incorrect.',
  EMPTY_FIELD_NOT_ALLOWED: "Field can't be empty.",
  MESSAGE_ARCHIVED: 'Message Archived.',
  MESSAGE_MARKED_AS_UNREAD: 'Message marked as unread.',
  SESSION_EXPIRED: 'Your session has expired. Please log in again.',
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  TASKS_COMPLETED: 'Tasks completed.',
  TASK_COMPLETED: 'Task completed.',
  TOKEN_INVALID: "Couldn't get auth token.",
  TOO_MANY_REQUESTS: 'Too many requests.',
  USER_NOT_FOUND: 'User is not found.',
  VERIFY_ACCOUNT_VIA_EMAIL: 'Please verify account via email.',
  SUCCESSFULLY_REQUESTED_DELETION_OF_ACCOUNT:
    'You successfully requested deletion of account. We will inform you when it is done.',
  UNSUCCESSFULLY_REQUESTED_DELETION_OF_ACCOUNT:
    'There was an error with your request. Please try again later.',
  MEMEBERSHIP_AGREEMENT_SUCCESS: 'Membership agreement signed successfuly',
};

import { ReactNode, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUserSelector } from 'store/selectors/selectors';
import { Container } from 'react-bootstrap';
import {
  getSidebarLinksHomeActions,
  getNavbarLinksHomeActions,
} from 'store/actions/homeActions';
import { getEventsCalendarAction } from 'store/actions/calendarActions';
import { useAppDispatch } from 'index';
import { IRootState } from 'lib/interfaces';
import { getUserAuthAction } from 'store/actions/authActions';
import useViewport from 'hooks/useViewport';
import PortalLayoutDesktop from 'components/PortalLayoutComponents/PortalLayoutDesktop';
import PortalLayoutMobile from 'components/PortalLayoutComponents/PortalLayoutMobile';
import { BREAKPOINTS } from 'utils/breakpoints';
import { ROUTES } from 'utils/constants';

export interface IProps {
  children: ReactNode;
  title: string;
  calendarPageActive?: boolean;
}

const PortalLayout = ({ children, title, calendarPageActive = false }: IProps) => {
  const loggedUser = useSelector((state: IRootState) => getUserSelector(state));
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { width } = useViewport();
  const lessThen770ScreenSize = useMemo(() => {
    return width < BREAKPOINTS.medium;
  }, [width]);

  useEffect(() => {
    dispatch(getUserAuthAction());
    dispatch(getSidebarLinksHomeActions());
    dispatch(getNavbarLinksHomeActions());
    dispatch(getEventsCalendarAction());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(loggedUser).length === 0) {
      history.push(ROUTES.LOGIN);
    }
  }, [history, loggedUser]);

  return (
    <div className="portal-layout">
      <Container fluid>
        {lessThen770ScreenSize ? (
          <PortalLayoutMobile>{children}</PortalLayoutMobile>
        ) : (
          <PortalLayoutDesktop title={title} calendarPageActive={calendarPageActive}>
            {children}
          </PortalLayoutDesktop>
        )}
      </Container>
    </div>
  );
};

export default PortalLayout;

import React from 'react';

const TwitterIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = '#093D20', fillOpacity = 0.2 } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_543_4536)">
        <path
          // eslint-disable-next-line max-len
          d="M5.03344 14.5C11.0697 14.5 14.3722 9.49783 14.3722 5.16127C14.3722 5.02065 14.3691 4.8769 14.3628 4.73627C15.0053 4.27167 15.5597 3.6962 16 3.0369C15.4017 3.3031 14.7664 3.47695 14.1159 3.55252C14.8009 3.14197 15.3137 2.49701 15.5594 1.73721C14.915 2.11907 14.2104 2.38844 13.4756 2.53377C12.9806 2.00774 12.326 1.65944 11.6131 1.54273C10.9003 1.42602 10.1688 1.5474 9.53183 1.88809C8.89486 2.22879 8.38787 2.76983 8.08923 3.42757C7.7906 4.08531 7.71695 4.82311 7.87969 5.5269C6.575 5.46142 5.29862 5.1225 4.13332 4.5321C2.96802 3.94169 1.9398 3.11299 1.11531 2.09971C0.696266 2.82219 0.568038 3.67713 0.756687 4.49076C0.945337 5.30439 1.43671 6.01566 2.13094 6.48002C1.60975 6.46347 1.09998 6.32315 0.64375 6.07064V6.11127C0.643283 6.86946 0.905399 7.60442 1.38554 8.19121C1.86568 8.778 2.53422 9.1804 3.2775 9.33002C2.7947 9.46212 2.28799 9.48136 1.79656 9.38627C2.0063 10.0383 2.41438 10.6086 2.96385 11.0176C3.51331 11.4265 4.17675 11.6538 4.86156 11.6675C3.69895 12.5808 2.26278 13.0761 0.784375 13.0738C0.522191 13.0734 0.260266 13.0573 0 13.0256C1.5019 13.9892 3.24902 14.501 5.03344 14.5Z"
          fill={fill}
          fillOpacity={fillOpacity}
        />
      </g>
      <defs>
        <clipPath id="clip0_543_4536">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TwitterIcon;

import { AppDispatch, AppThunk } from 'index';
import { IRootState } from 'lib/interfaces';
import moment from 'moment';
import { getCalendarEvents } from 'store/apiCalls';
import { TIME_FOR_UPDATE } from 'utils/constants';
import errorHandler from 'utils/errorHandler';

export const getEventsCalendarAction = (
  forceUpdate?: boolean,
): AppThunk<Promise<boolean | undefined>> => {
  return async (dispatch: AppDispatch, getState: () => IRootState) => {
    try {
      const {
        user: { events: eventsFromRedux = [], lastFetchedEventsMoment = '' } = {},
      } = getState();

      const timeDiff = moment().diff(lastFetchedEventsMoment, 'minute');
      const isLastFetchBiggerThanFiveMinutes = timeDiff > TIME_FOR_UPDATE;
      if (
        isLastFetchBiggerThanFiveMinutes
        || eventsFromRedux.length === 0
        || forceUpdate
      ) {
        const [data] = await getCalendarEvents();
        if (data) {
          dispatch({
            type: 'SET_EVENTS',
            items: data,
            lastFetchedEventsMoment: moment(),
          });
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } catch (error) {
      errorHandler(error);
    }
  };
};

/*
  Actions are named with this convention
  `${Descriptive Action Name}${Action Type}`
*/

import { AppDispatch, AppThunk } from 'index';
import { IRootState } from 'lib/interfaces';
import moment from 'moment';
import {
  getVideos,
  getPartners,
  getNavbarLinks,
  getSidebarLinks,
  getCheckListForUser,
  setTaskAsCompleted,
} from 'store/apiCalls';
import { ALERT_MESSAGES } from 'utils/alerts';
import { TIME_FOR_UPDATE } from 'utils/constants';

import errorHandler from 'utils/errorHandler';
import { swalInfo } from 'utils/toasts';

export const getChecklistHomeAction = (): AppThunk<Promise<[] | unknown>> => {
  return async () => {
    try {
      const res = await getCheckListForUser();
      if (res) {
        return res;
      } else {
        swalInfo(ALERT_MESSAGES.SOMETHING_WENT_WRONG);
        return [];
      }
    } catch (error) {
      errorHandler(error);
      return [];
    }
  };
};

export const setTaskAsCompletedTaskActions = (
  completedTasks = [],
): AppThunk<Promise<boolean>> => {
  return async () => {
    try {
      const taskResponse = await setTaskAsCompleted(completedTasks);
      if (taskResponse) {
        return true;
      } else {
        errorHandler(taskResponse);
        return false;
      }
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };
};

export const getSidebarLinksHomeActions = (): AppThunk<Promise<boolean | unknown>> => {
  return async (dispatch: AppDispatch, getState: () => IRootState) => {
    try {
      const {
        user: { resources: resourcesFromRedux = [], lastFetchResourcesMoment = '' } = {},
      } = getState();
      const timeDiff = moment().diff(lastFetchResourcesMoment, 'minute');
      const isLastFetchBiggerThanFiveMinutes = timeDiff > TIME_FOR_UPDATE;
      if (
        isLastFetchBiggerThanFiveMinutes
        || !lastFetchResourcesMoment
        || resourcesFromRedux?.length === 0
      ) {
        const [resources, error] = await getSidebarLinks();
        if (resources) {
          dispatch({
            type: 'SET_RESOURCES',
            resources,
            lastFetchResourcesMoment: moment(),
          });
          return true;
        } else if (error) {
          return false;
        }
      } else {
        return true;
      }
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };
};

export const getNavbarLinksHomeActions = (): AppThunk<Promise<boolean | unknown>> => {
  return async (dispatch: AppDispatch, getState: () => IRootState) => {
    try {
      const { user: { navbar: navbarFromRedux = [], lastFetchNavbarMoment = '' } = {} } = getState();
      const timeDiff = moment().diff(lastFetchNavbarMoment, 'minute');
      const isLastFetchBiggerThanFiveMinutes = timeDiff > TIME_FOR_UPDATE;
      if (
        isLastFetchBiggerThanFiveMinutes
        || !lastFetchNavbarMoment
        || navbarFromRedux?.length === 0
      ) {
        const [navbar, error] = await getNavbarLinks();
        if (navbar) {
          dispatch({ type: 'SET_NAVBAR', navbar, lastFetchNavbarMoment: moment() });
          return true;
        } else if (error) {
          return false;
        }
      } else {
        return true;
      }
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };
};

export const getPartnersHomeActions = (): AppThunk<Promise<boolean>> => {
  return async (dispatch: AppDispatch) => {
    try {
      const [data] = await getPartners();
      if (data) {
        dispatch({ type: 'SET_PARTNERS', partners: data });
        return true;
      } else return false;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };
};

export const getVideosHomeActions = (): AppThunk<Promise<boolean>> => {
  return async (dispatch: AppDispatch) => {
    try {
      const [data] = await getVideos();
      if (data) {
        dispatch({ type: 'SET_VIDEOS', videos: data });
        return true;
      } else return false;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };
};

import React from 'react';
import { useHistory } from 'react-router-dom';

const NotFoundPage = () => {
  const history = useHistory();

  return (
    <div className="error-boundary-component-wrapper">
      <h1>404</h1>
      <h2>Page not found.</h2>
      <p>The link you clicked may be broken or the page may have been removed.</p>
      <div className="error-boundary-component-btns">
        <div
          className="error-boundary-component-btn"
          onClick={() => {
            history.push('/');
          }}
        >
          Return to Dashboard
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;

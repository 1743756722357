import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
 Card, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import logo from '../../assets/img/logo-big.png';
import logoMobile from '../../assets/img/logo-white.png';
import { getUserAuthAction, logOutUserAuthAction } from 'store/actions/authActions';
import { useAppDispatch } from 'index';
import { getAccessTokenAuth0 } from 'auth0/auth0Auth';
import { logEventAnalytics } from '../../firebase/firebaseAnalytics';
import { FB_CONST } from 'utils/fb-constants';

function AuthorizationPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const getAuthToken = async () => {
      const authToken = await getAccessTokenAuth0();
      if (authToken) {
        const queries = new URLSearchParams(window.location.search);
        const state = queries.get('state') || '';

        if (state && !isChecked) {
          const getUserAuthPromise = dispatch(getUserAuthAction(true));
          const getUserResponse: boolean | string = await Promise.resolve(
            getUserAuthPromise,
          );
          setIsChecked(true);
          if (getUserResponse) {
            logEventAnalytics(FB_CONST.EVENT_TYPES.AUTHORIZATION_SUCCESS);
            history.push('/');
          } else {
            dispatch(logOutUserAuthAction());
          }
        }
      } else {
        dispatch(logOutUserAuthAction('token-invalid'));
      }
    };

    getAuthToken();
  }, [dispatch, history, isChecked]);

  return (
    <div className="login-page">
      <Container className="pt-5 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="card-background rounded-0 login-card">
              <Card.Header className="bg-transparent d-none d-md-block">
                <div className="text-muted text-center my-2 mobile-color">
                  Welcome to LambdaOnline.org
                </div>
              </Card.Header>
              <Card.Body className="px-lg-5 py-lg-5">
                <picture>
                  <source media="(min-width: 992px )" srcSet={logo} />
                  <source media="(max-width: 992px )" srcSet={logoMobile} />
                  <img alt="LCA logo" className="login-logo" src={logo} />
                </picture>
                <div className="text-center text-muted my-3 mobile-color">
                  Please wait...
                </div>
                <div className="text-center text-muted my-3">
                  <Spinner animation="border" role="status" className="mobile-color" />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AuthorizationPage;

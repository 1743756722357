import React from 'react';
import { IRootState, ISidebarLink } from 'lib/interfaces';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNumberOfUnreadMessagesSelector } from 'store/selectors/selectors';
import { Nav } from 'react-bootstrap';
import primaryLogo from '../../assets/img/logo-images/logo-primary.svg';
import { SIDEBAR_ITEMS } from 'utils/constants';
import SidebarLink from 'components/LeftSidebarComponents/SidebarLink';
import SidebarNextEventComponent from 'components/LeftSidebarComponents/SidebarNextEventComponent';

const LeftSidebarComponent = () => {
  const numberOfUnreadMessages = useSelector((state: IRootState) => getNumberOfUnreadMessagesSelector(state));

  return (
    <div>
      <Nav className="flex-column sidebar-wrapper">
        <div className="sidebar-logo">
          <Link to="/">
            <img src={primaryLogo} />
          </Link>
        </div>
        {SIDEBAR_ITEMS.map((item: ISidebarLink, i: number) => {
          return (
            <div className="sidebar-link-wrapper" key={i}>
              <SidebarLink
                item={item}
                numberOfUnreadMessages={numberOfUnreadMessages}
                index={i}
                activeOnlyWhenExact={i === 0}
              />
            </div>
          );
        })}
        {/* <SidebarTaskProgressComponent /> */}
        {/* <SidebarMarketingComponent /> */}
        <SidebarNextEventComponent />
      </Nav>
    </div>
  );
};

export default LeftSidebarComponent;

import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase';

export const logEventAnalytics = (
  eventName: string,
  _param?: {
    firebase_screen?: string;
    firebase_screen_class?: string;
    item_id?: string;
    item_name?: string;
    item_category?: string;
    item_category2?: string;
    item_brand?: string;
  },
) => {
  if (window.location.hostname !== 'localhost') {
    if (_param) {
      logEvent(analytics, eventName, _param);
    } else {
      logEvent(analytics, eventName);
    }
  }
};

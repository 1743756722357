import React from 'react';
import { Image } from 'react-bootstrap';

interface IProps {
  icon: string;
  handleShow: () => void;
}

const AccountComponent = ({ icon, handleShow }: IProps) => {
  return (
    <div className="right-sidebar-account">
      <Image src={icon} onClick={handleShow} />
    </div>
  );
};

export default AccountComponent;

import { AnyAction } from 'redux';
import { IInitReduxState, ILoggedUser } from '../../lib/interfaces';

const initState: IInitReduxState = {
  profile: {} as ILoggedUser,
  events: [],
  messages: [],
  archivedMessages: [],
  partners: [],
  videos: [],
  documents: [],
  intro: '',
  resources: [],
  navbar: [],
  membershipAgreement: {
    _id: '',
    id: 0,
    signedAgreement: false,
    signedAgreementUrl: '',
    delimiter: false,
    signedAt: '',
    createdAt: '',
    updatedAt: '',
  },
  lastFetchResourcesMoment: '',
  lastFetchNavbarMoment: '',
  lastFetchedEventsMoment: '',
  lastFetchedUserMoment: '',
};

export const userReducer = (
  state: IInitReduxState,
  action: AnyAction,
): IInitReduxState => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        profile: action.user,
        lastFetchedUserMoment: action.lastFetchedUserMoment,
      };
    case 'SET_INTRO_TEXT':
      return {
        ...state,
        intro: action.res,
      };
    case 'SET_MESSAGES':
      return {
        ...state,
        messages: action.messages,
      };
    case 'SET_ARCHIVED_MESSAGES':
      return {
        ...state,
        archivedMessages: action.archivedMessages,
      };
    case 'SET_EVENTS':
      return {
        ...state,
        events: action.items,
        lastFetchedEventsMoment: action.lastFetchedEventsMoment,
      };

    case 'SET_NAVBAR':
      return {
        ...state,
        navbar: action.navbar,
        lastFetchNavbarMoment: action.lastFetchNavbarMoment,
      };

    case 'SET_RESOURCES':
      return {
        ...state,
        resources: action.resources,
        lastFetchResourcesMoment: action.lastFetchResourcesMoment,
      };

    case 'SET_PARTNERS':
      return {
        ...state,
        partners: action.partners,
      };

    case 'SET_VIDEOS':
      return {
        ...state,
        videos: action.videos,
      };

    case 'SET_DOCUMENTS':
      return {
        ...state,
        documents: action.documents,
      };

    case 'SET_MEMBERSHIP_AGREEMENT':
      return {
        ...state,
        membershipAgreement: action.res,
      };

    case 'LOGOUT_SUCCESS':
      return initState;
    default:
      return initState;
  }
};

export default userReducer;

import React from 'react';
import { IProps } from 'layouts/PortalLayout/PortalLayout';
import { Col, Row } from 'react-bootstrap';
import RightSidebarComponent from 'layouts/PortalLayout/RightSidebarComponent';
import LeftSidebarComponent from 'layouts/PortalLayout/LeftSidebarComponent';
import moment from 'moment';

const PortalLayoutDesktop = ({ children, title, calendarPageActive = false }: IProps) => {
  return (
    <Row className="portal-layout-sidebar-container">
      <Col className="portal-layout-sidebar">
        <LeftSidebarComponent />
      </Col>
      <Col className="portal-layout-page">
        <div className="page-heading-wrapper">
          <div className="section-title">{title}</div>
          <div className="welcome-date">{moment().format('ddd, MMMM Do YYYY')}</div>
        </div>
        {children}
      </Col>
      <Col
        className={`portal-layout-right-sidebar ${
          calendarPageActive && 'portal-layout-right-sidebar-small'
        }`}
      >
        <RightSidebarComponent calendarPageActive={calendarPageActive} />
      </Col>
    </Row>
  );
};

export default PortalLayoutDesktop;

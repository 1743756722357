import React from 'react';

interface IProps {
  profileImg: string;
  securityUserImg?: string;
}

const ProfileImageComponent = ({ profileImg, securityUserImg }: IProps) => {
  return (
    <div>
      <img src={profileImg} width="64px" height="64px" />
      {securityUserImg ? (
        <div className="popup-modal-account-security-user">
          <img src={securityUserImg} width="24px" height="24px" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProfileImageComponent;

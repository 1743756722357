import React from 'react';
import { IRootState, ISidebarLink } from 'lib/interfaces';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getNumberOfUnreadMessagesSelector } from 'store/selectors/selectors';

interface IProps {
  item: ISidebarLink;
  index: number;
  activeOnlyWhenExact?: boolean;
}

const SidebarLinkMobile = ({ item, index = 0, activeOnlyWhenExact = false }: IProps) => {
  const {
    title = '',
    image = '',
    imageLight = '',
    url = '',
    notification = false,
  } = item;
  const match = useRouteMatch({
    path: url,
    exact: activeOnlyWhenExact,
  });
  const numberOfUnreadMessages = useSelector((state: IRootState) => getNumberOfUnreadMessagesSelector(state));

  return (
    <div className="navigation-mobile-link" key={index}>
      <Link
        to={url}
        onClick={() => index === 0 && window.scrollTo(0, 0)}
        className={`${match ? 'activeLinkItemMobile' : ''}`}
      >
        {notification && numberOfUnreadMessages !== 0 && (
          <div className="mobile-messages-notification">{numberOfUnreadMessages}</div>
        )}
        <img src={match ? imageLight : image} />
        {!match ? <div className="navigation-mobile-link-title">{title}</div> : ''}
      </Link>
    </div>
  );
};

export default SidebarLinkMobile;

import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
 Container, Row, Col, Card, Form, Spinner,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  checkIfEmailIsValidAuthAction,
  loginUserAuthAction,
  logOutUserAuthAction,
} from 'store/actions/authActions';
import { getUserSelector } from 'store/selectors/selectors';
import { swalInfo, swalError, swalSuccess } from 'utils/toasts';
import logo from '../../assets/img/logo-big.png';
import logoMobile from '../../assets/img/logo-white.png';
import { ALERT_MESSAGES } from 'utils/alerts';
import { IRootState } from 'lib/interfaces';
import { useAppDispatch } from 'index';
import { useHistory } from 'react-router-dom';
import { getAccessTokenAuth0 } from 'auth0/auth0Auth';
import { logEventAnalytics } from '../../firebase/firebaseAnalytics';
import { FB_CONST } from 'utils/fb-constants';
import { isValidEmail } from 'utils/helpers';

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const loggedUser = useSelector((state: IRootState) => getUserSelector(state));

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showMessageOnlyMembers, setShowMessageOnlyMembers] = useState(false);

  useEffect(() => {
    logEventAnalytics(FB_CONST.EVENT_TYPES.SCREEN_VIEW, {
      firebase_screen: FB_CONST.SCREEN_NAMES.LOGIN.FB_SCREEN,
      firebase_screen_class: FB_CONST.SCREEN_NAMES.LOGIN.FB_SCREEN_CLASS,
    });
  }, []);

  useEffect(() => {
    const checkForAuthToken = async () => {
      const token = await getAccessTokenAuth0();
      if (token) {
        history.push('/');
      }
    };

    const searchParams = new URLSearchParams(window.location.search);
    const verificationMessage = searchParams.get('message');
    const verificationStatus = searchParams.get('success');
    const authStatus = searchParams.get('authStatus');
    if (verificationMessage) {
      verificationStatus === 'true'
        ? swalSuccess(verificationMessage)
        : swalInfo(verificationMessage);
      window.history.replaceState(null, 'null', window.location.pathname);
    } else if (authStatus === 'SessionExpired') {
      dispatch(logOutUserAuthAction('session-expired'));
    } else if (authStatus === 'LoggedOutAfterSessionExpired') {
      swalInfo(ALERT_MESSAGES.SESSION_EXPIRED);
      window.history.replaceState(null, 'null', window.location.pathname);
    } else if (authStatus === 'EmailNotVerified') {
      dispatch(logOutUserAuthAction('email-not-verified'));
    } else if (authStatus === 'LoggedOutBecauseEmailNotVerified') {
      swalInfo(ALERT_MESSAGES.VERIFY_ACCOUNT_VIA_EMAIL);
      window.history.replaceState(null, 'null', window.location.pathname);
    } else if (authStatus === 'UserNotFound') {
      dispatch(logOutUserAuthAction('user-not-found'));
    } else if (authStatus === 'LoggedOutBecauseUserIsNotFound') {
      swalInfo(ALERT_MESSAGES.USER_NOT_FOUND);
      window.history.replaceState(null, 'null', window.location.pathname);
    } else if (authStatus === 'TokenInvalid') {
      swalInfo(ALERT_MESSAGES.TOKEN_INVALID);
      window.history.replaceState(null, 'null', window.location.pathname);
    } else if (Object.keys(loggedUser).length !== 0) {
      checkForAuthToken();
    }
  }, [history, dispatch, loggedUser]);

  const handleEmailValidation = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (email === '') {
        swalError(ALERT_MESSAGES.EMPTY_FIELD_NOT_ALLOWED);
        setIsLoading(false);
      } else if (!isValidEmail(email)) {
        swalError(ALERT_MESSAGES.EMAIL_FORMAT_INCORRECT);
        setIsLoading(false);
      } else {
        dispatch(checkIfEmailIsValidAuthAction(email)).then(
          ({ registered = true, error = false }) => {
            if (error) {
              setShowMessageOnlyMembers(true);
              setTimeout(() => {
                setShowMessageOnlyMembers(false);
              }, 5000);
              setIsLoading(false);
            } else {
              dispatch(loginUserAuthAction(registered, email));
              logEventAnalytics(FB_CONST.EVENT_TYPES.TAP_LOGIN);
              setIsLoading(false);
            }
          },
        );
      }
    } catch (error) {
      setIsLoading(false);
      return false;
    }
  };

  return (
    <div className="login-page">
      <Container className="py-1">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="card-background rounded-0 login-card">
              <Card.Body className="px-lg-5 py-lg-5">
                <picture>
                  <source media="(min-width: 992px )" srcSet={logo} />
                  <source media="(max-width: 992px )" srcSet={logoMobile} />
                  <img alt="LCA logo" className="login-logo" src={logo} />
                </picture>
                <h2 className="login-heading">HELLO, BRO</h2>
                <div className="text-center">Please enter email in order to proceed.</div>
                <Form
                  className="login-form"
                  onSubmit={(e) => {
                    handleEmailValidation(e);
                  }}
                >
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.trim())}
                    disabled={isLoading}
                    className="login-input"
                    autoComplete="on"
                  />
                  {showMessageOnlyMembers && (
                    <div className="email-warning-message">Only members allowed.</div>
                  )}
                  <button
                    type="button"
                    disabled={isLoading}
                    className="btn login-button"
                    onClick={(e) => {
                      handleEmailValidation(e);
                    }}
                  >
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        className="mobile-color"
                      />
                    ) : (
                      'LOGIN'
                    )}
                  </button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;

import React, { ReactNode, useMemo, useState } from 'react';
import { ROUTES, SIDEBAR_ITEMS_MOBILE } from 'utils/constants';
import { ISidebarLink } from 'lib/interfaces';
import SidebarLinkMobile from 'components/LeftSidebarComponents/SidebarLinkMobile';
import AccountComponent from 'components/UtilitiesComponents/AccountComponent';
import logo from '../../assets/img/horizontal-logo.png';
import logoWhite from '../../assets/img/logo-images/logo-horizontal-white.svg';
import userIcon from '../../assets/img/utilities-icons/user.png';
import whiteUserIcon from '../../assets/img/utilities-icons/whiteUser.svg';
import AccountModal from 'components/RightSidebarComponents/AccountModal';
import { useLocation } from 'react-router-dom';
import useViewport from 'hooks/useViewport';
import { BREAKPOINTS } from 'utils/breakpoints';

interface IProps {
  children: ReactNode;
}

const PortalLayoutMobile = ({ children }: IProps) => {
  const [show, setShow] = useState(false);
  const { pathname = '' } = useLocation();
  const { width } = useViewport();
  const lessThen700ScreenSize = useMemo(() => {
    return width < BREAKPOINTS.medium;
  }, [width]);

  return (
    <div className="portal-layout-mobile">
      <div className="welcome-component-header-xs">
        <img
          src={pathname === ROUTES.CALENDAR && lessThen700ScreenSize ? logoWhite : logo}
        />
        <AccountComponent
          icon={
            pathname === ROUTES.CALENDAR && lessThen700ScreenSize
              ? whiteUserIcon
              : userIcon
          }
          handleShow={() => setShow(true)}
        />
        <AccountModal show={show} handleClose={() => setShow(false)} />
      </div>
      <div>{children}</div>
      <div className="portal-layout-mobile-navigation">
        {SIDEBAR_ITEMS_MOBILE.map((item: ISidebarLink, i: number) => {
          return (
            <SidebarLinkMobile
              key={i}
              item={item}
              index={i}
              activeOnlyWhenExact={i === 2 && true}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PortalLayoutMobile;

import { lazyWithRetry } from 'utils/lazyWithRetry';

export const LoginPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "login" */
      './views/LoginPage/LoginPage'
    ),
);

export const DashboardPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "dashboard" */
      './views/DashboardPage/DashboardPage'
    ),
);

export const CalendarPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "calendar" */
      './views/CalendarPage/CalendarPage'
    ),
);

export const MessagesPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "messages" */
      './views/MessagesPage/MessagesPage'
    ),
);

export const DocumentLibraryPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "documentLibrary" */
      './views/DocumentLibraryPage/DocumentLibraryPage'
    ),
);

export const MembershipAgreementPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "membershipAgreement" */
      './views/MembershipAgreementPage/MembershipAgreementPage'
    ),
);

export const ResourcesPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "resources" */
      './views/ResourcesPage/ResourcesPage'
    ),
);

export const PartnersPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "partners" */
      './views/PartnersPage/PartnersPage'
    ),
);

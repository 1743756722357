import React, { useState } from 'react';
import userIcon from '../../assets/img/utilities-icons/user.png';
import AccountModal from 'components/RightSidebarComponents/AccountModal';
import NewProgramItem from 'components/RightSidebarComponents/NewProgramItem';
import { SOCIAL_MEDIA_ITEMS } from 'utils/constants';
import SocialMediaItem from 'components/RightSidebarComponents/SocialMediaItem';
import { useSelector } from 'react-redux';
import {
  IRightSidebarNewProgramItem,
  IRootState,
  ISocialMediaItem,
} from 'lib/interfaces';
import { getSidebarAdditionalItems } from 'store/selectors/selectors';
import AccountComponent from 'components/UtilitiesComponents/AccountComponent';

interface IProps {
  calendarPageActive?: boolean;
}
const RightSidebarComponent = ({ calendarPageActive = false }: IProps) => {
  const sidebarAdditionalItems = useSelector((state: IRootState) => getSidebarAdditionalItems(state));
  const [show, setShow] = useState(false);

  return (
    <div
      className={`right-sidebar-wrapper ${
        calendarPageActive && 'right-sidebar-wrapper-small'
      }`}
    >
      <AccountComponent icon={userIcon} handleShow={() => setShow(true)} />
      <div className="right-sidebar-details">
        <div className={`${calendarPageActive && 'right-sidebar-details-hidden'}`}>
          <div className="right-sidebar-details-description">
            <div className="section-title title">What&apos;s new</div>
            <div className="desc">
              We are happy to announce the launch of new programs:
            </div>
          </div>
          <div className="right-sidebar-programs">
            {sidebarAdditionalItems.length !== 0 ? (
              sidebarAdditionalItems.map(
                (item: IRightSidebarNewProgramItem, i: number) => {
                  return <NewProgramItem item={item} key={i} />;
                },
              )
            ) : (
              <div className="no-sidebar-item-programs">
                No available programs right now.
              </div>
            )}
          </div>
        </div>
        <div className="right-sidebar-social-media">
          {SOCIAL_MEDIA_ITEMS.map((item: ISocialMediaItem, i: number) => {
            return <SocialMediaItem item={item} key={i} />;
          })}
        </div>
      </div>
      <AccountModal show={show} handleClose={() => setShow(false)} />
    </div>
  );
};

export default RightSidebarComponent;

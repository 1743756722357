import dashboardIcon from '../assets/img/sidebar-icons/dashboard.svg';
import calendarIcon from '../assets/img/sidebar-icons/calendar.svg';
import messagesIcon from '../assets/img/sidebar-icons/messages.svg';
import documentIcon from '../assets/img/sidebar-icons/documents.svg';
import resourcesIcon from '../assets/img/sidebar-icons/resources.svg';

import dashboardIconLight from '../assets/img/sidebar-icons/dashboard-light.svg';
import calendarImageLight from '../assets/img/sidebar-icons/calendar-light.svg';
import messagesIconLight from '../assets/img/sidebar-icons/messages-light.svg';
import documentIconLight from '../assets/img/sidebar-icons/documents-light.svg';
import resourcesIconLight from '../assets/img/sidebar-icons/resources-light.svg';

export const TIME_FOR_UPDATE = 15;
export const MIN_CHARS_FOR_TRUNCATE_EVENTS = 30;
export const EVENT_ITEM_LIMIT_DESKTOP = 4;
export const EVENT_ITEM_LIMIT_MOBILE = 3;

export const ROUTES = {
  LOGIN: '/login',
  AUTHORIZATION: '/authorization',
  HOMEPAGE: '/',
  CALENDAR: '/calendar',
  MESSAGES: '/messages',
  DOCUMENTS: '/document-library',
  RESOURCES: '/resources',
  PARTNERS: '/partners',
  MEMBERSHIP_AGREEMENT: '/membership-agreement',
  CONTACT_US: '/contact-us',
  TERMS_OF_SERVICE: '/terms-of-service',
};

export const SIDEBAR_ITEMS = [
  {
    title: 'Dashboard',
    url: ROUTES.HOMEPAGE,
    image: dashboardIcon,
  },
  {
    title: 'Calendar',
    url: ROUTES.CALENDAR,
    image: calendarIcon,
  },
  {
    title: 'Messages',
    url: ROUTES.MESSAGES,
    notification: true,
    image: messagesIcon,
  },
  {
    title: 'Documents',
    url: ROUTES.DOCUMENTS,
    image: documentIcon,
  },
  {
    title: 'Resources',
    url: ROUTES.RESOURCES,
    image: resourcesIcon,
  },
];

export const SIDEBAR_ITEMS_MOBILE = [
  {
    title: 'Calendar',
    url: ROUTES.CALENDAR,
    image: calendarIcon,
    imageLight: calendarImageLight,
  },
  {
    title: 'Messages',
    url: ROUTES.MESSAGES,
    notification: true,
    image: messagesIcon,
    imageLight: messagesIconLight,
  },
  {
    title: 'Dashboard',
    url: ROUTES.HOMEPAGE,
    image: dashboardIcon,
    imageLight: dashboardIconLight,
  },
  {
    title: 'Folders',
    url: ROUTES.DOCUMENTS,
    image: documentIcon,
    imageLight: documentIconLight,
  },
  {
    title: 'Resources',
    url: ROUTES.RESOURCES,
    image: resourcesIcon,
    imageLight: resourcesIconLight,
  },
];

export const SOCIAL_MEDIA_ITEMS = [
  {
    id: 'twitter',
    url: 'https://twitter.com/LambdaChiAlpha',
  },
  {
    id: 'linkedin',
    url: 'https://www.linkedin.com/company/lambda-chi-alpha/',
  },
  {
    id: 'facebook',
    url: 'https://www.facebook.com/LambdaChi',
  },
  {
    id: 'instagram',
    url: 'https://www.instagram.com/lambdachialphaooa/',
  },
];

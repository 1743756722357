import { useAppDispatch } from 'index';
import { ALERT_MESSAGES } from 'utils/alerts';
import { swalError, swalSuccess } from 'utils/toasts';
import { requestToDeleteAccountUserAction } from 'store/actions/userActions';
import { Modal } from 'react-bootstrap';
import { logEventAnalytics } from '../../firebase/firebaseAnalytics';
import { FB_CONST } from 'utils/fb-constants';

interface IProps {
  show: boolean;
  handleCloseDeleteModal: () => void;
  handleCloseAccountModal: () => void;
}

const DeleteAccountModal = ({
  show = false,
  handleCloseDeleteModal,
  handleCloseAccountModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  const requestToDeleteAccountOnClick = async () => {
    dispatch(requestToDeleteAccountUserAction()).then((res: boolean) => {
      if (res) {
        swalSuccess(ALERT_MESSAGES.SUCCESSFULLY_REQUESTED_DELETION_OF_ACCOUNT);
        logEventAnalytics(FB_CONST.EVENT_TYPES.TAP_ACCOUNT_DELETE);
        handleCloseDeleteModal();
        handleCloseAccountModal();
      } else {
        swalError(ALERT_MESSAGES.UNSUCCESSFULLY_REQUESTED_DELETION_OF_ACCOUNT);
        handleCloseDeleteModal();
        handleCloseAccountModal();
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseDeleteModal}
      fullscreen="md-down"
      centered
      className="delete-account-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>DELETE ACCOUNT</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Deletion of your account will take some time to complete. We&apos;ll inform you
        when it&apos;s done. Are you sure you want to delete your account?
      </Modal.Body>
      <Modal.Footer>
        <div className="delete-modal-btn" onClick={handleCloseDeleteModal}>
          Close
        </div>
        <div className="delete-modal-btn confirm" onClick={requestToDeleteAccountOnClick}>
          Confirm
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAccountModal;

import { AppThunk } from 'index';
import { requestToDeleteAccount } from 'store/apiCalls';

import errorHandler from 'utils/errorHandler';

export const requestToDeleteAccountUserAction = (): AppThunk<Promise<boolean | unknown>> => async () => {
    try {
      const res = await requestToDeleteAccount();
      return res;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };

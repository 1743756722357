import { ICalendarDaysOfWeek, IDocumentsArray, IDynamicObjectKey } from 'lib/interfaces';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';

export const convertStringToKebabCase = (str: string) => {
  const regExMatchValue = str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
  );
  return regExMatchValue?.map((x: string) => x.toLowerCase()).join('-') || '';
};

export const formatPhoneNumber = (str: string) => {
  return str.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || '';
};

export const isValidPassword = (password: string) => {
  const validPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return validPass.test(password) || '';
};

export const isValidEmail = (email: string) => {
  const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validEmail.test(email.trim()) || '';
};

export const sortDocumentsByProperty = (
  array: [],
  property: string,
  sorting: boolean,
) => {
  const arrayFolders = array.filter((a: IDocumentsArray) => a.fileName === null);
  const arrayDocuments = array.filter((a: IDocumentsArray) => a.fileName !== null);
  if (property === 'date') {
    const sortedFolders = arrayFolders.sort((a: IDocumentsArray, b: IDocumentsArray) => {
      const xc = a.updatedAt ? moment(a.updatedAt) : moment('1980-01-01T12:00:00.000Z');
      const yc = b.updatedAt ? moment(b.updatedAt) : moment('1980-01-01T12:00:00.000Z');
      if (sorting) {
        return yc.isAfter(xc) ? -1 : 1;
      } else {
        return yc.isBefore(xc) ? -1 : 1;
      }
    });
    const sortedDocuments = arrayDocuments.sort(
      (a: IDocumentsArray, b: IDocumentsArray) => {
        const xc = a.updatedAt ? moment(a.updatedAt) : moment('1980-01-01T12:00:00.000Z');
        const yc = b.updatedAt ? moment(b.updatedAt) : moment('1980-01-01T12:00:00.000Z');
        if (sorting) {
          return yc.isAfter(xc) ? -1 : 1;
        } else {
          return yc.isBefore(xc) ? -1 : 1;
        }
      },
    );
    return [...sortedFolders, ...sortedDocuments];
  } else {
    const sortedFolders = arrayFolders.sort(
      (a: IDynamicObjectKey, b: IDynamicObjectKey) => {
        if (sorting) {
          return a[property].toLowerCase() < b[property].toLowerCase() ? 1 : -1;
        } else {
          return a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1;
        }
      },
    );
    const sortedDocuments = arrayDocuments.sort(
      (a: IDynamicObjectKey, b: IDynamicObjectKey) => {
        if (sorting) {
          return a[property].toLowerCase() < b[property].toLowerCase() ? 1 : -1;
        } else {
          return a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1;
        }
      },
    );
    return [...sortedFolders, ...sortedDocuments];
  }
};

export function trunticateText(
  str: string,
  maxLength: number,
  { side = 'end', ellipsis = '...' } = {},
) {
  // e.g:
  // textEllipsis('a very long text', 10); ===>  "a very ..."
  // textEllipsis('a very long text', 10, { side: 'start' }); ===> // "...ng text"

  if (str.length > maxLength) {
    switch (side) {
      case 'start':
        return ellipsis + str.slice(-(maxLength - ellipsis.length));
      case 'end':
      default:
        return str.slice(0, maxLength - ellipsis.length) + ellipsis;
    }
  }
  return str;
}

export const getWeekDataForCalendar = (passedDate?: string) => {
  const currentDate = passedDate !== '' ? moment(passedDate) : moment();
  const weekStart = currentDate.clone().startOf('isoWeek');
  let daysOfWeek: ICalendarDaysOfWeek[] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= 6; i++) {
    const day = moment(weekStart).add(i, 'days').format('D');
    const fullDay = moment(weekStart).add(i, 'days').format();
    const weekName = moment(weekStart).add(i, 'days').format('dd');
    daysOfWeek = [...daysOfWeek, { day, weekName, fullDay }];
  }

  return daysOfWeek;
};

export const escapeHTML = (htmlCode: string) => {
  htmlCode = htmlCode.toString();
  return htmlCode
    .replace(/<[^>]*(>|$)|&nbsp;|&#34;|&#39;|&zwnj;|&raquo;|&laquo;|&gt;|\n/g, ' ')
    .replace(/&amp;/g, '&');
};

export const sanitizeHTMLString = (htmlString: string) => {
  return sanitizeHtml(htmlString, {
    allowedTags: [
      'h1',
      'h2',
      'blockquote',
      'hr',
      'li',
      'ol',
      'p',
      'pre',
      'ul',
      'a',
      'abbr',
      'b',
      'bdi',
      'bdo',
      'br',
      'cite',
      'dfn',
      'em',
      'i',
      'kbd',
      'mark',
      'q',
      's',
      'samp',
      'small',
      'strong',
      'sub',
      'sup',
      'u',
      'wbr',
      'iframe',
      'img',
    ],
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
      iframe: ['src'],
    },
    transformTags: {
      a: sanitizeHtml.simpleTransform('a', { target: '_blank' }),
    },
    selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
    allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
    allowedSchemesByTag: {
      img: ['data', 'src', 'alt', 'http', 'https'],
    },
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
  });
};

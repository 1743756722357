import axios from 'axios';

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

/**
 * A function that executes API call for user confirmation email. Checks if the user is eligible to register on the site.
 * @returns {Array} - Array that returns res object that consists of boolean variable or string if there was something wrong with API call.
 * @param {string} email - sent as param to API call from user input.
 */
export const confirmEmail = async (email: string) => {
  try {
    const { data = {} } = await axios.post(`${baseUrl}/user/confirm-email`, {
      email,
    });
    return [data, null];
  } catch (error) {
    return [null, 'Something went wrong.'];
  }
};

/**
 * A function that executes API call for getting user data object when user is trying to login.
 * @returns {Array} - An array that returns user object after API call that consists of user data or error object that is interpreted that that user is not registered.
 */
export const getUser = async () => {
  try {
    const { data: user = {} } = await axios.get(`${baseUrl}/user`);
    return [user, null];
  } catch (error) {
    return [null, error];
  }
};

/**
 * A function that executes API call for getting messages sent to a user from admin.
 * @param {number} page - param passed to use for pagination
 * @param {number} limit - param used to limit the number of results that are sent back by API
 * @returns {Array} - An array that returns object after API call that consists of data object that has array of messages or error object.
 */
export const getMessagesForUser = async (page = 1, limit = 1000) => {
  try {
    const { data = null } = await axios.get(
      `${baseUrl}/message?page=${page}&limit=${limit}`,
    );
    return [data, null];
  } catch (messagesError) {
    return [null, messagesError];
  }
};

/**
 * A function that executes API call for getting messages sent to a user from admin.
 * @param {number} page - param passed to use for pagination
 * @param {number} limit - param used to limit the number of results that are sent back by API
 * @returns {Array} - An array that returns object after API call that consists of data object that has array of messages or error object.
 */
export const getArchivedMessagesForUser = async (page = 1, limit = 1000) => {
  try {
    const { data = null } = await axios.get(
      `${baseUrl}/message/archive?page=${page}&limit=${limit}`,
    );
    return [data, null];
  } catch (messagesError) {
    return [null, messagesError];
  }
};

/**
 * A function that executes API call for deleting specific message from a user
 * @param {string} id - param that represents unique id for a message user wants to delete
 * @returns {Array} - An array that returns object after API call that consists of data object that has array of messages without message with passed id or error object.
 */
export const archiveMessage = async (id: string) => {
  try {
    const { data = {} } = await axios.patch(`${baseUrl}/user/messages/archive/${id}`);
    return [data, null];
  } catch (messagesError) {
    return [null, messagesError];
  }
};

/**
 * A function that executes API call for unarchiving specific message from a user
 * @param {string} id - param that represents unique id for a message user wants to delete
 * @returns {Array} - An array that returns object after API call that consists of data object that has array of messages without message with passed id or error object.
 */
export const unarchiveMessage = async (id: string) => {
  try {
    const { data = {} } = await axios.patch(`${baseUrl}/user/messages/unarchive/${id}`);
    return [data, null];
  } catch (messagesError) {
    return [null, messagesError];
  }
};

/**
 * A function that executes API call for setting a unread status for a specific message.
 * @param {string} id - param that represents unique id for a message.
 * @returns {Array} - An array that returns object after API call that consists of data object that has array of messages with defined message status or error object.
 */
export const setMessageAsUnreadRead = async (id: string) => {
  try {
    const { data = {} } = await axios.patch(`${baseUrl}/user/messages/unread/${id}`);
    return [data, null];
  } catch (error) {
    return [null, error];
  }
};

/**
 * A function that executes API call for setting a read status for a specific message.
 * @param {string} id - param that represents unique id for a message.
 * @returns {Array} - An array that returns object after API call that consists of data object that has array of messages with defined message status or error object.
 */
export const setMessageAsRead = async (id: string) => {
  try {
    const { data = {} } = await axios.patch(`${baseUrl}/user/messages/read/${id}`);
    return [data, null];
  } catch (messagesError) {
    return [null, messagesError];
  }
};

/**
 * A function that executes API call for getting all the tasks for a user.
 * @returns {Object} - An array that returns object of data that has all tasks defined or array that has error object inside.
 */
export const getCheckListForUser = async () => {
  try {
    const { data = null } = await axios.get(`${baseUrl}/task`);
    return data;
  } catch (error) {
    return [error];
  }
};

/**
 * A function that executes API call for setting completed tasks.
 * @param {Array} completedTasks - param that is passed after user checked and sent all tasks completed.
 * @returns {Object} - An array that returns object of data that has all tasks defined or array that has error object inside.
 */
export const setTaskAsCompleted = async (completedTasks = []) => {
  try {
    const { data = {} } = await axios.patch(
      `${baseUrl}/user/task/complete`,
      completedTasks,
    );
    return data;
  } catch (error) {
    return [error];
  }
};

/**
 * A function that executes API call for getting all events set for a user and place them in calendar.
 * @returns {Array} - An array that returns object of data that has all events or array that has error object inside.
 */
export const getCalendarEvents = async () => {
  try {
    const { data = null } = await axios.get(`${baseUrl}/event`);
    return [data, null];
  } catch (error) {
    return [null, error];
  }
};

/**
 * A function that executes API call for getting all links that are placed in sidebar.
 * @returns {Array} - An array that returns object of data with sidebar links or array that has error object inside.
 */
export const getSidebarLinks = async () => {
  try {
    const { data = null } = await axios.get(`${baseUrl}/sidebar`);
    return [data, null];
  } catch (error) {
    return [null, error];
  }
};

/**
 * A function that executes API call for getting all links that are placed in navbar.
 * @returns {Array} - An array that returns object of data with navbar links or array that has error object inside.
 */
export const getNavbarLinks = async () => {
  try {
    const { data = null } = await axios.get(`${baseUrl}/navbar`);
    return [data, null];
  } catch (error) {
    return [null, error];
  }
};

/**
 * A function that executes API call for getting all partners data that are showing on Home page.
 * @returns {Array} - An array that returns object of data with data about partners or array that has error object inside.
 */
export const getPartners = async () => {
  try {
    const { data = null } = await axios.get(`${baseUrl}/partner`);
    return [data, null];
  } catch (error) {
    return [null, error];
  }
};

/**
 * A function that executes API call for getting videos for Home page.
 * @returns {Array} - An array that returns object of data or array that has error object inside.
 */
export const getVideos = async () => {
  try {
    const { data = null } = await axios.get(`${baseUrl}/gallery`);
    return [data, null];
  } catch (error) {
    return [null, error];
  }
};

/**
 * A function that executes API call for getting documents and files for a user.
 * @param {Number} page - param that sets number of page, used for pagination
 * @param {Number} limit - param that sets limit of documents sent back
 * @returns {Array} - An array that returns object of data or array that has error object inside.
 */
export const getDocumentsForUser = async (page: number, limit: number) => {
  try {
    const { data = {} } = await axios.get(
      `${baseUrl}/document?page=${page}&limit=${limit}`,
    );
    return [data, null];
  } catch (error) {
    return [null, error];
  }
};

/**
 * A function that executes API call for getting URL link of a single file.
 * @param {String} id - unique param for getting link of a single file
 * @returns {Object} - An array that returns object of data or array that has error object inside.
 */
export const getSingleFileDownloadURL = async (id: string) => {
  try {
    const { data = '' } = await axios.get(`${baseUrl}/document/download/${id}`);
    return data;
  } catch (error) {
    return false;
  }
};

export const requestToDeleteAccount = async () => {
  try {
    const res = await axios.delete(`${baseUrl}/user/delete-account/request`);
    return res || false;
  } catch (error) {
    return error;
  }
};

export const getFolderDocumentLibrary = async (path = '') => {
  try {
    if (path === '' || path === null) {
      const { data = [] } = await axios.get(`${baseUrl}/document/`);
      return data;
    } else {
      const { data = [] } = await axios.get(`${baseUrl}/document?path=${path}`);
      return data;
    }
  } catch (error) {
    return error;
  }
};

export const getMembershipAgreement = async () => {
  try {
    const { data = {} } = await axios.get(`${baseUrl}/membership`);
    return data || false;
  } catch (error) {
    return error;
  }
};

export const signMembershipAgreement = async () => {
  try {
    const res = await axios.patch(`${baseUrl}/membership`);
    return res || false;
  } catch (error) {
    return error;
  }
};

export const getMembershipAgreementFile = async (url: string) => {
  try {
    const { data = '' } = await axios.get(`${baseUrl}/upload`, { params: { url } });
    return data;
  } catch (error) {
    return error;
  }
};

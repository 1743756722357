import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import rootReducer from './store/reducer/rootReducer';
import storage from 'redux-persist/lib/storage';
import thunk, { ThunkAction } from 'redux-thunk';
import { Provider, useDispatch } from 'react-redux';
import {
 createStore, applyMiddleware, compose, AnyAction,
} from 'redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
import ErrorBoundaryComponent from 'components/ErrorBoundaryComponent/ErrorBoundaryComponent';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = process.env.REACT_APP_ENVIRONMENT !== 'prod'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('lca-hub-app') as HTMLElement);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAppDispatch = (): any => useDispatch<AppDispatch>();

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundaryComponent>
          <App />
        </ErrorBoundaryComponent>
      </PersistGate>
    </Provider>
  </StrictMode>,
);

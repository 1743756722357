import React from 'react';
import { IRightSidebarNewProgramItem } from 'lib/interfaces';
import { logEventAnalytics } from '../../firebase/firebaseAnalytics';
import { FB_CONST } from 'utils/fb-constants';

interface IProps {
  item: IRightSidebarNewProgramItem;
}

const NewProgramItem = ({ item }: IProps) => {
  const { title = '', url = '', _id } = item;
  return (
    <div className="sidebar-new-program-component">
      <div className="sidebar-program-heading">New program</div>
      <div className="sidebar-program-title">{title}</div>
      <div
        className="sidebar-program-button"
        onClick={() => {
          if (
            url.includes(
              'https://leadershipskillscompetency.kaplanperformanceacademy.com',
            )
          ) {
            logEventAnalytics(FB_CONST.EVENT_TYPES.TAP_KAPLAN_LINK, {
              item_id: _id,
              item_brand: url,
            });
          }
          logEventAnalytics(FB_CONST.EVENT_TYPES.TAP_DASHBOARD_LINK, {
            item_id: _id,
            item_name: title,
            item_brand: url,
          });
        }}
      >
        <a href={url} target="_blank" rel="noreferrer">
          Access
        </a>
      </div>
    </div>
  );
};

export default NewProgramItem;

import React from 'react';
import { ISidebarLink } from 'lib/interfaces';
import { Link, useRouteMatch } from 'react-router-dom';

interface IProps {
  item: ISidebarLink;
  numberOfUnreadMessages: number;
  index: number;
  activeOnlyWhenExact?: boolean;
}

const SidebarLink = ({
  item,
  numberOfUnreadMessages = 0,
  index = 0,
  activeOnlyWhenExact = false,
}: IProps) => {
  const {
 title = '', url = '', notification = false, image = '',
} = item;
  const match = useRouteMatch({
    path: url,
    exact: activeOnlyWhenExact,
  });

  return (
    <Link
      to={url}
      onClick={() => index === 0 && window.scrollTo(0, 0)}
      className={`sidebar-link ${match ? 'activeLinkItem' : ''}`}
    >
      <img src={image} />
      <div className="sidebar-link-title">
        <span>{title}</span>
        {notification && numberOfUnreadMessages !== 0 && (
          <span
            className={`sidebar-link-messages-notification ${
              numberOfUnreadMessages > 99 ? 'smaller-font-size' : 'normal-font-size'
            }`}
          >
            {numberOfUnreadMessages}
          </span>
        )}
      </div>
    </Link>
  );
};

export default SidebarLink;

import React, { useMemo } from 'react';
import { IRootState } from 'lib/interfaces';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getEventsSelector } from 'store/selectors/selectors';
import { trunticateText } from 'utils/helpers';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'utils/constants';

const SidebarNextEventComponent = () => {
  const eventsFromStore = useSelector((state: IRootState) => getEventsSelector(state));
  const history = useHistory();

  const nextComingEvent = useMemo(() => {
    const filteredAndSortedEventsArray = eventsFromStore
      ?.filter((item) => moment(item.date) > moment() && item)
      .sort((x, y) => {
        return moment(x.date) > moment(y.date) ? 1 : -1;
      });
    return filteredAndSortedEventsArray[0] || {};
  }, [eventsFromStore]);
  const { event = '', date = '' } = nextComingEvent || {};

  return (
    <>
      {nextComingEvent ? (
        <div
          className={`sidebar-marketing-component ${
            nextComingEvent && date !== '' ? '' : 'hide-sidebar-marketing'
          }`}
          onClick={() => {
            history.push(ROUTES.CALENDAR);
            window.scrollTo(0, 0);
          }}
        >
          {/* <div className="sidebar-marketing-header">{event}</div> */}
          <div className="sidebar-marketing-desc">
            {event.length > 500 ? trunticateText(event, 300) : event}
          </div>
          <div className="sidebar-marketing-footer">
            {moment(date).format('ddd, MMMM Do YYYY')}
          </div>
        </div>
      ) : (
        <div className="events-empty">No event for now.</div>
      )}
    </>
  );
};

export default SidebarNextEventComponent;

export const FB_CONST = {
  EVENT_TYPES: {
    // Page and Screen Views
    SCREEN_VIEW: 'screen_view',
    // Login Actions
    TAP_LOGIN: 'tap_login',
    AUTHORIZATION_SUCCESS: 'authorization_success',
    // Acount Actions
    VIEW_MEMBERSHIP_AGREEMENT: 'view_membership_agreement',
    TAP_LOGOUT: 'tap_logout',
    TAP_ACCOUNT_DELETE: 'tap_account_delete',
    // Dashboard Actions
    TAP_VIDEO_PLAY: 'tap_video_play',
    TAP_TASK_COMPLETE: 'tap_task_complete',
    TAP_PARTNER_URL: 'tap_partner_url',
    TAP_DASHBOARD_LINK: 'tap_dashboard_link',
    TAP_KAPLAN_LINK: 'tap_kaplan_link',
    TAP_DASHBOARD_EVENT: 'tap_dashboard_event',
    TAP_SOCIAL_PROFILE: 'tap_social_profile',
    // Messages Actions
    TAP_MESSAGE_ARCHIVE: 'tap_message_archive',
    TAP_MESSAGE_UNREAD: 'tap_message_unread',
    TAP_MESSAGE_UNARCHIVE: 'tap_message_unarchive',
    TAP_ON_MESSAGE_MODAL: 'tap_on_message_modal',
    TAP_MESSAGE_OPEN: 'tap_message_open',
    // Calendat Actions
    TAP_EVENT_OPEN: 'tap_event_open',
    TAP_ON_EVENT_MODAL: 'tap_on_event_modal',
    // Documents Actions
    TAP_DOCUMENT_SORT: 'tap_document_sort',
    TAP_DOCUMENT_FOLDER: 'tap_document_folder',
    TAP_DOCUMENT_FILE: 'tap_document_file',
    // Resources Actions
    TAP_RESOURCE_ITEM: 'tap_resource_item',
  },
  SCREEN_NAMES: {
    LOGIN: {
      FB_SCREEN: 'Login Screen',
      FB_SCREEN_CLASS: 'LoginScreen',
    },
    DASHBOARD: {
      FB_SCREEN: 'Dashboard Screen',
      FB_SCREEN_CLASS: 'DashboardScreen',
    },
    MESSAGES: {
      FB_SCREEN: 'Messages Screen',
      FB_SCREEN_CLASS: 'MessagesScreen',
    },
    CALENDAR: {
      FB_SCREEN: 'Calendar Screen',
      FB_SCREEN_CLASS: 'CalendarScreen',
    },
    DOCUMENTS: {
      FB_SCREEN: 'Documents Screen',
      FB_SCREEN_CLASS: 'DocumentsScreen',
    },
    RESOURCES: {
      FB_SCREEN: 'Resources Screen',
      FB_SCREEN_CLASS: 'ResourcesScreen',
    },
    ACCOUNT: {
      FB_SCREEN: 'Account Screen',
      FB_SCREEN_CLASS: 'AccountScreen',
    },
    PARTNERS: {
      FB_SCREEN: 'Partners Screen',
      FB_SCREEN_CLASS: 'PartnersScreen',
    },
  },
};

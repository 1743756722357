import errorHandler from 'utils/errorHandler';
import { confirmEmail, getUser } from 'store/apiCalls';
import { loginUserWithEmailAndPasswordAuth0, logoutUser } from 'auth0/auth0Auth';
import moment from 'moment';
import { TIME_FOR_UPDATE } from 'utils/constants';
import { AppDispatch, AppThunk } from 'index';
import { IRootState } from 'lib/interfaces';

export const getUserAuthAction = (forceUpdate?: boolean): AppThunk<Promise<boolean>> => async (dispatch: AppDispatch, getState: () => IRootState) => {
    try {
      const { user: { lastFetchedUserMoment = '' } = {} } = getState();

      const timeDiff = moment().diff(lastFetchedUserMoment, 'minute');
      const isLastFetchBiggerThanFiveMinutes = timeDiff > TIME_FOR_UPDATE;

      if (isLastFetchBiggerThanFiveMinutes || forceUpdate) {
        const [user = {}, userError = {}] = await getUser();

        if (user) {
          dispatch({ type: 'SET_USER', user, lastFetchedUserMoment: moment() });
          return user;
        } else if (userError) {
          return userError;
        } else {
          return false;
        }
      } else return true;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };

export const loginUserAuthAction = (isRegistered: boolean, email: string): AppThunk<Promise<boolean>> => async () => {
    try {
      const res = await loginUserWithEmailAndPasswordAuth0(isRegistered, email);
      if (res) {
        return true;
      } else return false;
    } catch (err) {
      errorHandler(err);
      return false;
    }
  };

export const checkIfEmailIsValidAuthAction = (email: string): AppThunk<Promise<boolean | unknown>> => async () => {
    try {
      const [res, error] = await confirmEmail(email);
      if (error) {
        return { registered: null, error: true };
      } else {
        const { registered = true } = res;
        return { registered, error: false };
      }
    } catch (error) {
      errorHandler(error);
      return { registered: null, error: true };
    }
  };

export const logOutUserAuthAction = (status?: string): AppThunk<Promise<boolean>> => async (dispatch: AppDispatch) => {
    try {
      await logoutUser(status);
      dispatch({ type: 'LOGOUT_SUCCESS' });
      return true;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import { getAccessTokenAuth0 } from 'auth0/auth0Auth';
import { swalInfo } from './utils/toasts';
import { ALERT_MESSAGES } from 'utils/alerts';
import AuthorizationPage from 'views/AuthorizationPage/AuthorizationPage';
import NotFoundPage from 'views/NotFoundPage/NotFoundPage';
import 'assets/styles/app.scss';
import { ROUTES } from 'utils/constants';
import PrivateRoute from 'components/Routes/PrivateRoute';
import LoadingPage from 'views/LoadingPage/LoadingPage';
import LoginPage from 'views/LoginPage/LoginPage';
// import ContactUsPage from 'views/ContactUsPage/ContactUsPage';
// import TermsOfServicePage from 'views/TermsOfServicePage/TermsOfServicePage';
import {
  CalendarPageLazy,
  DashboardPageLazy,
  DocumentLibraryPageLazy,
  // LoginPageLazy,
  MembershipAgreementPageLazy,
  MessagesPageLazy,
  PartnersPageLazy,
  ResourcesPageLazy,
} from 'pages';

// * Axios Settings & Interceptors
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

axios.interceptors.request.use(
  async (config) => {
    const token = await getAccessTokenAuth0();
    if (token) {
      if (config.headers !== undefined) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response.status === 401
      && error.response.data.message.includes('Please verify account via email')
    ) {
      window.location.href = '/login?authStatus=EmailNotVerified';
    } else if (
      error.response.data.statusCode === 401
      && error.response.data.message.includes('jwt')
    ) {
      window.location.href = '/login?authStatus=TokenInvalid';
    } else if (error.response.status === 401) {
      window.location.href = '/login?authStatus=SessionExpired';
    } else if (
      error.response.status === 404
      && error.response.config.url.endsWith('/user')
    ) {
      window.location.href = '/login?authStatus=UserNotFound';
    } else if (error.response.status === 403) {
      swalInfo(ALERT_MESSAGES.TOO_MANY_REQUESTS);
    } else if (
      error.response.status === 412
      && !window.location.href.includes('/membership-agreement')
    ) {
      window.location.href = '/membership-agreement';
    }

    return Promise.reject(error);
  },
);
export class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={<LoadingPage />}>
          <Switch>
            <Route path={ROUTES.LOGIN} exact component={LoginPage} />
            <Route path={ROUTES.AUTHORIZATION} component={AuthorizationPage} />
            <PrivateRoute path={ROUTES.HOMEPAGE} exact component={DashboardPageLazy} />
            <PrivateRoute path={ROUTES.CALENDAR} exact component={CalendarPageLazy} />
            <PrivateRoute path={ROUTES.MESSAGES} exact component={MessagesPageLazy} />
            <PrivateRoute
              path={ROUTES.DOCUMENTS}
              exact
              component={DocumentLibraryPageLazy}
            />
            <PrivateRoute
              path={ROUTES.MEMBERSHIP_AGREEMENT}
              component={MembershipAgreementPageLazy}
            />
            <PrivateRoute path={ROUTES.RESOURCES} component={ResourcesPageLazy} />
            <PrivateRoute path={ROUTES.PARTNERS} component={PartnersPageLazy} />
            <Route path="" component={NotFoundPage} />
            {/* <Route path={ROUTES.CONTACT_US} component={ContactUsPage} />
            <Route path={ROUTES.TERMS_OF_SERVICE} component={TermsOfServicePage} /> */}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;

import React, { useCallback, useEffect, useState } from 'react';
import PopupModal from 'components/UtilitiesComponents/PopupModal';
import { useAppDispatch } from 'index';
import { ILoggedUser, IRootState, IDynamicObjectKey } from 'lib/interfaces';
import { useSelector } from 'react-redux';
import { getMembershipAgreementFile } from 'store/apiCalls';
import {
  getMembershipAgreementFileSelector,
  getUserSelector,
} from 'store/selectors/selectors';
import profileImg from '../../assets/img/utilities-icons/profile.png';
import securityUserImg from '../../assets/img/utilities-icons/security-user.svg';
import deleteIcon from '../../assets/img/utilities-icons/trash.svg';
import agreementIcon from '../../assets/img/utilities-icons/membership_agreement.svg';
import logoutIcon from '../../assets/img/utilities-icons/logout.svg';
import { logOutUserAuthAction } from 'store/actions/authActions';
import ProfileImageComponent from 'components/UtilitiesComponents/ProfileImageComponent';
import DeleteAccountModal from './DeleteAccountModal';
import { logEventAnalytics } from '../../firebase/firebaseAnalytics';
import { FB_CONST } from 'utils/fb-constants';

interface IProps {
  show: boolean;
  handleClose: () => void;
}

const AccountModal = ({ show = false, handleClose }: IProps) => {
  const loggedUser = useSelector((state: IRootState) => getUserSelector(state));
  const signedUrl = useSelector((state: IRootState) => getMembershipAgreementFileSelector(state)) || '';
  const dispatch = useAppDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    primary_email: primaryEmail = {} as IDynamicObjectKey,
    member_status: memberStatus = '',
    member_status_reason: memberStatusReason = '',
  } = loggedUser as ILoggedUser;
  const { email_address: emailAddress = '' } = primaryEmail || {};

  useEffect(() => {
    if (show) {
      logEventAnalytics(FB_CONST.EVENT_TYPES.SCREEN_VIEW, {
        firebase_screen: FB_CONST.SCREEN_NAMES.ACCOUNT.FB_SCREEN,
        firebase_screen_class: FB_CONST.SCREEN_NAMES.ACCOUNT.FB_SCREEN_CLASS,
      });
    }
  }, [show]);

  const downloadMembershipAgreement = useCallback(async (url: string) => {
    if (url) {
      const agreementURL = await getMembershipAgreementFile(url);
      logEventAnalytics(FB_CONST.EVENT_TYPES.VIEW_MEMBERSHIP_AGREEMENT);
      window.open(agreementURL, '_blank', 'noopener,noreferrer');
    }
  }, []);

  const logoutHandler = useCallback(() => {
    dispatch(logOutUserAuthAction());
    logEventAnalytics(FB_CONST.EVENT_TYPES.TAP_LOGOUT);
  }, [dispatch]);

  return (
    <PopupModal showPopup={show} onHidePopup={handleClose}>
      <div className="popup-modal-account">
        <ProfileImageComponent
          profileImg={profileImg}
          securityUserImg={securityUserImg}
        />
        <div className="popup-modal-account-user">
          <div className="user-credentials">{`${firstName} ${lastName}`}</div>
          <div className="user-status">{`${memberStatus} ${memberStatusReason}`}</div>
          <div className="user-email">{emailAddress}</div>
        </div>
      </div>
      <hr className="user-page-horizontal-line" />
      <div className="user-actions">
        <div className="user-actions-block" onClick={logoutHandler}>
          <img src={logoutIcon} width="24px" height="24px" />
          <span className="user-action-logout">LOGOUT</span>
        </div>
        <div className="user-actions-block" onClick={() => setShowDeleteModal(true)}>
          <img src={deleteIcon} width="24px" height="24px" />
          <span className="user-action-delete">DELETE ACCOUNT</span>
        </div>
        {signedUrl && (
          <div
            className="user-actions-block"
            onClick={() => downloadMembershipAgreement(signedUrl)}
          >
            <img src={agreementIcon} width="24px" height="24px" />
            <span className="user-action-membership-agreement">
              View Membership Agreement
            </span>
          </div>
        )}
      </div>
      <DeleteAccountModal
        show={showDeleteModal}
        handleCloseDeleteModal={() => setShowDeleteModal(false)}
        handleCloseAccountModal={handleClose}
      />
    </PopupModal>
  );
};

export default AccountModal;

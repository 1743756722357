import { useCallback } from 'react';
import { ISocialMediaItem } from 'lib/interfaces';
import FacebookIcon from 'components/Icons/FacebookIcon';
import InstagramIcon from 'components/Icons/InstagramIcon';
import LinkedinIcon from 'components/Icons/LinkedinIcon';
import TwitterIcon from 'components/Icons/TwitterIcon';
import { logEventAnalytics } from '../../firebase/firebaseAnalytics';
import { FB_CONST } from 'utils/fb-constants';

interface IProps {
  item: ISocialMediaItem;
  isMobile?: boolean;
}

const SocialMediaItem = ({ item, isMobile }: IProps) => {
  const { url = '', id = '' } = item;

  const resolveIcons = useCallback(
    (itemID: string) => {
      switch (itemID) {
        case 'facebook':
          return <FacebookIcon fillOpacity={isMobile ? 1 : 0.2} />;
        case 'instagram':
          return <InstagramIcon fillOpacity={isMobile ? 1 : 0.2} />;
        case 'linkedin':
          return <LinkedinIcon fillOpacity={isMobile ? 1 : 0.2} />;
        case 'twitter':
          return <TwitterIcon fillOpacity={isMobile ? 1 : 0.2} />;
        default:
          break;
      }
    },
    [isMobile],
  );

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      key={id}
      onClick={() => {
        logEventAnalytics(FB_CONST.EVENT_TYPES.TAP_SOCIAL_PROFILE, {
          item_name: id.charAt(0).toUpperCase() + id.slice(1),
          item_brand: url,
        });
      }}
    >
      {resolveIcons(id)}
    </a>
  );
};

export default SocialMediaItem;

import { IRootState } from 'lib/interfaces';

export const getUserSelector = (state: IRootState) => {
  const {
    user: { profile = {} },
  } = state;
  return profile || {};
};

export const getMessagesSelector = (state: IRootState) => {
  const {
    user: { messages = '' },
  } = state;
  return messages || '';
};

export const getArchivedMessagesSelector = (state: IRootState) => {
  const {
    user: { archivedMessages = '' },
  } = state;
  return archivedMessages || '';
};

export const getDocumentsSelector = (state: IRootState) => {
  const {
    user: { documents = [] },
  } = state;
  return documents || [];
};

export const getEventsSelector = (state: IRootState) => {
  const {
    user: { events = [] },
  } = state;
  return events || [];
};

export const getIntroTextSelector = (state: IRootState) => {
  const { user: { intro = '' } = {} } = state;
  return intro || '';
};

export const getUserUnreadMessagesSelector = (state: IRootState) => {
  const { user: { profile: { unreadMessages = [] } = {} } = {} } = state;
  return unreadMessages || [];
};

export const getSidebarAdditionalItems = (state: IRootState) => {
  const { user: { navbar = [] } = {} } = state;
  return navbar || [];
};

export const getSidebarDataSelector = (state: IRootState) => {
  const { user: { resources = [] } = {} } = state;
  return resources || [];
};

export const getPartnersSelector = (state: IRootState) => {
  const { user: { partners = [] } = {} } = state;
  return partners || [];
};

export const getUserTierSelector = (state: IRootState) => {
  const { user: { profile: { tier = '' } = {} } = {} } = state;
  return tier || '';
};

export const getNumberOfUnreadMessagesSelector = (state: IRootState) => {
  const { user: { profile: { unreadMessages = [] } = {} } = {} } = state;
  return unreadMessages.length || 0;
};

export const getMembershipAgreementFileSelector = (state: IRootState) => {
  const { user = {} } = state;
  const { membershipAgreement = {} } = { ...user };
  const { signedAgreementUrl = '' } = { ...membershipAgreement };
  return signedAgreementUrl || '';
};

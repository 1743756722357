import PortalLayout from 'layouts/PortalLayout/PortalLayout';
import {
 Card, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/img/logo-big.png';
import logoMobile from '../../assets/img/logo-white.png';

function LoadingPage() {
  const location = useLocation();

  if (location.pathname === '/login') {
    return (
      <div className="login-page">
        <Container className="pt-5 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="card-background rounded-0 login-card">
                <Card.Body className="px-lg-5 py-lg-5">
                  <picture>
                    <source media="(min-width: 992px )" srcSet={logo} />
                    <source media="(max-width: 992px )" srcSet={logoMobile} />
                    <img alt="LCA logo" className="login-logo" src={logo} />
                  </picture>
                  <div className="text-center text-muted my-3 mobile-color">
                    Loading...
                  </div>
                  <div className="text-center text-muted my-3">
                    <Spinner animation="border" role="status" className="mobile-color" />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <PortalLayout title="Loading...">
        <div />
      </PortalLayout>
    );
  }
}

export default LoadingPage;

/* eslint-disable no-console */
import { swalError } from './toasts';
import { ALERT_MESSAGES } from './alerts';

export default function errorHandler(error) {
  console.log('error', error);
  const { code = '', message = '' } = error;
  console.log('code', code);
  console.log('message', message);
  if (message === '' || message.includes('Request failed with status code')) {
    swalError(ALERT_MESSAGES.SOMETHING_WENT_WRONG);
  } else swalError(message);
}

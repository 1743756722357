import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { getAccessTokenAuth0 } from 'auth0/auth0Auth';
import LoadingPage from 'views/LoadingPage/LoadingPage';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: undefined,
    };
  }

  async componentDidMount() {
    const isAuthenticatedResponse = await getAccessTokenAuth0();

    this.setState({
      isAuthenticated: isAuthenticatedResponse,
    });
  }

  render() {
    const { component: Component } = this.props;
    const { isAuthenticated = undefined } = this.state;
    if (isAuthenticated !== undefined) {
      if (!isAuthenticated) {
        return <Redirect to="/login" />;
      } else if (isAuthenticated) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Route {...this.props} comp={<Component {...this.props} />} />;
      } else {
        return <Redirect to="/" />;
      }
    }
    return <LoadingPage />;
  }
}

export default withRouter(PrivateRoute);

import React, { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import closeBtn from '../../assets/img/utilities-icons/close-square.svg';

interface IPopupModalProps {
  showPopup: boolean;
  onHidePopup: () => void;
  children: ReactNode;
}

const PopupModal = ({ showPopup, onHidePopup, children }: IPopupModalProps) => {
  return (
    <Modal
      show={showPopup}
      animation={false}
      centered
      onHide={onHidePopup}
      className="popup-modal"
    >
      <Modal.Header
        className="popup-modal-close"
        bsPrefix="modal-header"
        onClick={onHidePopup}
      >
        <div style={{ paddingTop: '4px' }}>CLOSE</div>
        <img src={closeBtn} />
      </Modal.Header>
      <Modal.Body className="popup-modal-body">{children}</Modal.Body>
    </Modal>
  );
};

export default PopupModal;
